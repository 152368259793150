<template>
    <div class="content">
      <div class="SearchBar">
        <el-button @click="closeadd" size="mini" class="backbtn" style="margin: 30px 0px 40px 30px;"
          >返回</el-button
        >
        <div style="position: absolute;top: 0;right: 20px;">
          <el-button @click="addevent" size="mini" type="primary" style="margin: 30px 0px 40px 30px;"
            >添加第三方施工</el-button
          >
          <el-button @click="skip" size="mini" type="primary" style="margin: 30px 0px 40px 30px;"
            >添加报装申请</el-button
          >
        </div>
        <el-form
          ref="search"
          class="searchForm"
          :inline="true"
          label-position="right"
          style="width: 600px"
          label-width="160px"
          :model="paramsData"
          :rules="rule"
        >
          <el-form-item label="报修类型" prop="equipmentTypees">
            <el-cascader
                  size="mini"
                  multiple
                  ref="cascader"
                  style="width: 300px"
                  :options="selectDownArray"
                  v-model="paramsData.equipmentTypees"
                  :props="{ label: 'name', value: 'code'}"
                  separator=">"
                  collapse-tags
                  :disabled="paramsData.status ==2 && cornerMark == 'ywgd'"
                  @change="deformation">
                </el-cascader>
          </el-form-item>
          
            <el-form-item label="用户类型">
              <el-radio-group
                v-model="paramsData.residentType"
                @change="updataResident"
              >
                <el-radio :label="1">居民用户</el-radio>
                <el-radio :label="2">非居民用户</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="关联用户">
              <el-input
                size="mini"
                style="width: 300px"
                placeholder="请选择"
                v-model="paramsData.residentName"
                readonly
                show-word-limit
                @focus="selectEvent()"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contactName">
              <el-input
                v-model="paramsData.contactName"
                size="mini"
                style="width: 300px"
                name="limitLength"
                maxlength="30"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contactMobile">
              <el-input
                v-model="paramsData.contactMobile"
                size="mini"
                maxlength="30"
                name="limitLength"
                style="width: 300px"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="备用联系电话">
              <el-input
                v-model="paramsData.contactMobileEx"
                size="mini"
                maxlength="30"
                name="limitLength"
                style="width: 300px"
                placeholder="请输入备用联系电话"
              ></el-input>
            </el-form-item>
            <!-- 业务表单 -->
          <div v-if="cornerMark == 'ywgd'">
            <el-form-item label="报修地址" prop="address">
              <el-input
                v-model="paramsData.address"
                size="mini"
                maxlength="30"
                name="limitLength"
                style="width: 300px"
                placeholder="请输入报修地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="paramsData.remark"
                type="textarea"
                size="mini"
                style="width: 300px"
                placeholder="请输入备注"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item label="优先级：" prop="priorLevel">
              <el-radio-group
                v-model="paramsData.priorLevel"
                style="width: 300px"
              >
                <el-radio
                  :label="item.id"
                  v-for="(item, index) in priorityList"
                  :key="index"
                  >{{ item.priorLevel }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="来源" prop="source" style="width: 300px">
              <el-radio-group v-model="paramsData.source" :disabled='paramsData.source>2'>
                <el-radio :label="1">电话</el-radio>
                <el-radio :label="2">大厅</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <!-- 投诉表单 -->
           <div v-if="cornerMark == 'tsgd'">
            <el-form-item label="投诉类型" prop="complainType">
              <el-radio-group
                v-model="paramsData.complainType"
                @change="typeChange"
              >
                <el-radio :label="1">服务</el-radio>
                <el-radio :label="2">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="被投诉人" prop="complainedUser" v-if="paramsData.complainType != 2" key="complainedUser">
              <el-input
                v-model="paramsData.complainedUser"
                size="mini"
                style="width: 300px"
                maxlength="30"
                placeholder="请输入被投诉人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="事件" prop="event" class="events">
              <el-input
                v-model="paramsData.event"
                type="textarea"
                size="mini"
                style="width: 300px"
                placeholder="请输入事件描述"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item label="来源" prop="source" style="width: 300px">
              <el-radio-group v-model="paramsData.source" :disabled='paramsData.source>2'>
                <el-radio :label="1">电话</el-radio>
                <el-radio :label="2">大厅</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <!-- 咨询表单 -->
           <div v-if="cornerMark == 'zxgd'">
            <el-form-item label="备注">
              <el-input
                v-model="paramsData.remark"
                type="textarea"
                size="mini"
                style="width: 300px"
                placeholder="请输入备注"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item label="状态" prop="solveStatus" v-if="!this.$route.query.id">
              <el-radio-group v-model="paramsData.solveStatus">
                <el-radio :label="1">未解决</el-radio>
                <el-radio :label="2">已解决</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="说明" v-if="paramsData.solveStatus == 2 && !this.$route.query.id">
              <el-input
                v-model="paramsData.solveRemark"
                type="textarea"
                size="mini"
                style="width: 300px"
                placeholder="请输入说明"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item label="来源" prop="source" style="width: 300px">
              <el-radio-group v-model="paramsData.source" :disabled='paramsData.source>2'>
                <el-radio :label="1">电话</el-radio>
                <el-radio :label="2">大厅</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item label="" prop="" label-width="0">
            <el-button
              size="mini"
              type="primary"
              v-if="!this.$route.query.id"
              @click="save"
              class="backbtn"
              >确定</el-button
            >
            <el-button
              size="mini"
              type="primary"
              v-if="this.$route.query.id"
              @click="updateData"
              class="backbtn"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
       <!-- 用户人员列表 -->
    <el-dialog
      :visible.sync="eventListD"
      width="1084px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form :model="searchEvent" ref="DeviceData" :inline="true">
        <el-form-item label="">
          <el-input
            clearable
            size="mini"
            placeholder="请输入用户名称/联系人/电话/地址"
            v-model="searchEvent.keyword"
            style="width: 250px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="" label-width="0px">
          <el-button
            size="mini"
            type="primary"
            @click="getEventList"
            style="margin-left: 10px"
            >搜索</el-button
          >
          <el-button
            size="mini"
            type="warning"
            style="margin-left: 10px"
            @click="resizeEvent"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        border
        :data="tableDataEvents"
        height="450px"
        tooltip-effect="dark"
        ref="multipleTable"
        v-if="paramsData.residentType == 1"
        highlight-current-row
        @current-change="handleSelectionChangeEvent"
      >
        <el-table-column
          property="residentName"
          label="户主姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          property="mobile"
          label="户主电话"
          align="center"
        ></el-table-column>
        <el-table-column
          property="userTypeName"
          label="用户分类"
          align="center"
        ></el-table-column>
        <el-table-column
          property="addrName"
          label="片区"
          align="center"
        ></el-table-column>
        <el-table-column
          property="address"
          label="地址"
          align="center"
        ></el-table-column>
      </el-table>
      <el-table
        border
        :data="tableDataEvents"
        height="450px"
        tooltip-effect="dark"
        ref="multipleTable"
        v-if="paramsData.residentType == 2"
        highlight-current-row
        @current-change="handleSelectionChangeEvent"
      >
        <el-table-column
          property="userName"
          label="用户名称"
          align="center"
        ></el-table-column>
        <el-table-column
          property="contact"
          label="联系人"
          align="center"
        ></el-table-column>
        <el-table-column
          property="userPhone"
          label="电话"
          align="center"
        ></el-table-column>
        <el-table-column
          property="userTypeName"
          label="用户分类"
          align="center"
        ></el-table-column>
        <el-table-column
          property="addrName"
          label="片区"
          align="center"
        ></el-table-column>
        <el-table-column
          property="address"
          label="地址"
          align="center"
        ></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <div style="display: inline-block; float: left">
          <el-pagination
            @size-change="handleSizeChangeEvent"
            style="margin-top: 0px"
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="totalEvents"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="searchEvent.size"
            :current-page="searchEvent.current"
            @current-change="handleCurrentChangeEvent"
          >
          </el-pagination>
        </div>
        <div style="display: inline-block">
          <el-button size="small" @click="eventListD = false">取 消</el-button>
          <el-button size="small" @click="submitSelectEvent" type="primary"
            >确 认</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 添加第三方施工事件 -->
    <el-dialog
      title="添加事件"
      width="25%"
      :visible.sync="showAddModel"
      :show-close="false"
      :close-on-click-modal="false"
      @close="closeeventDialog('form')"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rule">
        <el-form-item label="事件类型：" prop="eventCodeName">
          <el-cascader
            style="width: 100%"
            :options="eventTypeList"
            v-model="form.eventCodeName"
            :props="{ label: 'name', value: 'id' }"
            separator=">"
            clearable
            collapse-tags
            @change="eventCodeSelect"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="地址："
          prop="eventAddress"
          label-width="120px"
        >
          <el-input v-model="form.eventAddress" placeholder="请选择地址">
            <i
              class="el-icon-location"
              style="color: #1082ff; font-size: 18px; margin-right: 10px"
              slot="suffix"
              @click="showModel = true"
            >
            </i>
          </el-input>
        </el-form-item>
        <el-form-item label="巡线员：" prop="lineAttendant" label-width="120px">
          <el-select
            clearable
            style="width: 100%"
            value-key="userId"
            v-model="form.lineAttendant"
            placeholder="报送人"
          >
            <el-option
              v-for="item in peopleList"
              :key="item.id"
              :label="item.username + '(' + item.mobile + ')'"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="上报人电话："
           prop="eventPlone"
          label-width="120px"
        >
          <el-input
            v-model="form.eventPlone"
            placeholder="请输入上报人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" label-width="120px">
          <el-input
            v-model="form.eventRemark"
            type="textarea"
            maxlength="200"
            placeholder="请输入备注"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddModel = false">取 消</el-button>
        <el-button type="primary" @click="submitAdd('form')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择位置 -->
    <el-dialog
      :visible.sync="showModel"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <mapDialog @closeModal="closeModel"></mapDialog>
    </el-dialog>
    <!-- 报装的新增 -->
    <el-dialog
      title="报装人信息"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
      @close="clearnF('form')">
      <el-form ref="addform" :model="form" label-width="130px" :rules="rules" size="small">
        <div>
          <el-row>
            <el-col :span="8">
          <el-form-item label="用气类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择" @change="selectType"   style="width:173px">
                <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </el-col>
           <el-col :span="8" >
          <el-form-item :label="form.type==1?'法人姓名':'产权人姓名'"  prop="name" name="limitLength" maxlength="100">
            <el-input v-model="form.name" :placeholder="form.type==1?'请输入法人姓名':'请输入产权人姓名'"  name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
           <el-col :span="8"  >
          <el-form-item :label="form.type==1?'法人身份证号':'身份证号'" prop="idcard">
            <el-input v-model="form.idcard" :placeholder="form.type==1?'请输入法人身份证号':'请输入身份证号'"   name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
            <el-col :span="8"  v-if="form.type==1">
          <el-form-item label="公司名称" prop="corporation" name="limitLength" maxlength="100">
            <el-input v-model="form.corporation" placeholder="请输入公司名称"   name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="16">
          <el-form-item :label="form.type==1?'公司地址':'地址'" prop="address" name="limitLength" maxlength="100">
            <el-input v-model="form.address" :placeholder="form.type==1?'请输入公司地址':'请输入地址'"  name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
          <el-form-item label="联系人电话" prop="tel">
            <el-input v-model="form.tel" placeholder="联系人电话"  name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item label="用途" prop="useDesc">
            <el-select v-model="form.useDesc"  placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in addUseList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
          </el-col>
          
          </el-row>
          <el-row>
           <el-col :span="24">
          <el-form-item :label="form.type==1?'法人身份证':'产权人身份证'" prop="endDate">
            <div style="display:flex;width:400px">
               <div style="flex:1;overflow:hidden;height:125px;width:190px;">
            <el-upload
                ref="upload1"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun1"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg1"
                :file-list="repairBeforFilefileList1"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="1"
                 :on-exceed="msgLimit"
                >
                <img src="../../../assets/image/upload1.png" style="" class="uploadImg1">
                
            </el-upload>
               </div>
                <div style="flex:1;overflow:hidden;height:125px;width:190px;">
            <el-upload
                ref="upload2"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun2"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg2"
                :file-list="repairBeforFilefileList2"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="1"
                 :on-exceed="msgLimit"
                >
                <img src="../../../assets/image/upload2.png" class="uploadImg1">
            </el-upload>
                </div>
            </div>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
          <el-form-item :label="form.type==1?'营业执照':'房产证(购房合同)'" prop="urlList">
            
            <el-upload
                ref="upload"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun3"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove3"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg3"
                :file-list="repairBeforFilefileList3"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="10"
                 :on-exceed="msgLimit"
                >
               <img src="../../../assets/image/upload3.png" class="uploadImg1">
               
                
            </el-upload>

            
          </el-form-item>
         </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import { userPageList,unUserPageList,businessSaveOrder,
YWorderDetails,
YWupdateOrder,
ZXorderDetails,
ZXupdateOrder,
ZXsaveOrder,
TSsaveOrder,
TSupdateOrder,
TSorderDetails } from "@/RequestPort/serviceOrders";
// 获取报修列表
import {dicpage} from "@/RequestPort/Login/LoginRequest";
import {
  dicByType,
  getTimeoutList,
  zypatrolGetUserList,
  saveevent,
} from "@/RequestPort/maintenance";
import mapDialog from "../../lineMalking/mapDialog";
import {selectDictListByParentCode } from '@/apis/commonType'
import {uploadImg} from "@/RequestPort/apply/apply.js"
import {bzApply_pageCompany,bzApply_save,getDateTime} from '@/RequestPort/report/reportApply'
export default {
  components: { mapDialog },
  data() {
    return {
      paramsData: {
        residentType:1,
        equipmentTypees:'',
        source:1,
        priorLevel:"1",
        contactName:'',
        contactMobile:'',
        address:'',
        complainedUser:'',
        complainType:1
      },
      priorityList:[],// 优先级列表
      tableData: [], //  表格数据
      selectDownArray:[],//报修列表
      cornerMark:'ywgd',// 报修类型
      eventListD:false,//选择用户弹窗
      searchEvent:{ current: 1, size: 100 },//用户列表查询条件
      searchEventZancun:{},
      tableDataEvents:[],// 用户列表
      search: { current: 1, size: 100,timeType:'1'},//搜索
      totalEvents:0,// 用户总数
      showAddModel:false,//第三方施工
      form:{
        eventCodeName: [],
        eventAddress: "",
      },// 第三方施工表单
      // 选择位置弹窗
      showModel: false,
      // 事件类型
      eventTypeList: [],
      // 巡线员
      peopleList: [],
      // 报装弹窗
      dialogVisible:false,
      // 用气类型
       typeList:[{label:'工商户（企业客户）',value:'1'},{label:'个人户（居民客户）',value:'2'}],
       // 图片
       fileList1:[],
      fileList2:[],
      fileList3:[],
      urlList1:[],
      urlList2:[],
      urlList3:[],
      upload1Add:true,
      upload2Add:true,
      repairBeforFilefileList1:[],
      repairBeforFilefileList2:[],
      repairBeforFilefileList3:[],
      // 用途
      addUseList:[],
      // 校验
      rule:{
        equipmentTypees: [
          { required: true, message: "请选择报修类型", trigger: ["blur",'change'] },
        ],
        contactName: [
          { required: true, message: "请输入联系人", trigger: ["blur","change"] },
        ],
        contactMobile: [
          { required: true, message: "请输入联系电话", trigger: ["blur","change"]  },
        ],
        address: [
          { required: true, message: "请输入报修详细地址", trigger: ["blur","change"]  },
        ],
        priorLevel: [
          { required: true, message: "请选择优先级", trigger: "change" },
        ],
        source: [
          { required: true, message: "请选择来源", trigger: "change" },
        ],
        complainType: [
          { required: true, message: "请选择投诉类型", trigger: "change" },
        ],
        complainedUser:[
          { required: true, message: "请输入被投诉人", trigger: "blur" },
        ],
        event:[
          { required: true, message: "请输入事件", trigger: "blur" },
        ],
        solveStatus: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
        eventCodeName: [
          { required: true, message: "请选择事件类型", trigger: "change" },
        ],
        eventAddress: [
          { required: true, message: "请选择地址", trigger: "change" },
        ],
        lineAttendant: [
          { required: true, message: "请选择巡线员", trigger: "change" },
        ],
        eventPlone: [
          {
            required: true,
            message: "请输入上报人电话",
            trigger: ["blur", "change"],
            // pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          },
        ],
        
      },
      rules:{
        type:{type:'string',required: true,message: '请选择用气类型',trigger: ['blur', 'change']},
        name:{type:'string',required: true,message: '请输入姓名',trigger: ['blur', 'change']},
			 idcard:{type:'string',required: true,message: '请输入正确的身份证号',trigger: ['blur', 'change'],pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/},
			 corporation:{type:'string',required: true,message: '请输入公司名称',trigger: ['blur', 'change']},
			 address:{type:'string',required: true,message: '请输入地址',trigger: ['blur', 'change']},
			 tel:{type:'string',required: true,message: '请输入正确的联系人电话',pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,trigger: ['blur', 'change']},
			 useDesc:{required: true,message: '请选择用途',trigger: ['blur', 'change']}
       },
       repairBeforFileFormData: new FormData(),
    };
  },
  mounted() {
    // 优先级
    getTimeoutList().then((res) => {
      this.priorityList = res.data;
    });
    // 获取维修员
    zypatrolGetUserList({ type: 1 }).then((res) => {
      this.maintainList = res.data;
      this.peopleList = res.data;
    });
    this.showEventTypeList()
    this.getOption();
    // 是编辑的情况
    if(this.$route.query.id) {
      // 报修类型字典
      this.Getdicpage(this.$route.query.first)
      this.compile(this.$route.query.id,this.$route.query.first)
    } else {
      // 报修类型字典
      this.Getdicpage()
    }
    if(this.$refs.search) {
      this.$nextTick(() => {
        this.$refs.search.clearValidate()
      })
    }
  },
  methods: {
    typeChange(val){
      if(val == 2 ) {
        this.paramsData.complainedUser = ''
      }
    },
    //返回
    closeadd() {
      this.$router.push({
        path: "/home/repairRecord",
        query: { first: this.$route.query.first },
      });
    },
    /**
     * 报装弹窗
     */
    skip(){

      if(this.$refs.addform){
        this.$refs['addform'].clearValidate();
      }
      
      this.form.type='1'
      this.getOption(this.form.type);
      this.dialogVisible = true;
    },
    /**
     * 添加报装
     */
    saveadd(){
       this.$refs['addform'].validate(valid => {
            if (valid) {
              if(this.urlList1.length>0){
                  this.form.idcard1Image = this.urlList1[0]
              }else{
                this.form.idcard1Image =''
              }
              if(this.urlList2.length>0){
                  this.form.idcard2Image = this.urlList2[0]
              }else{
                this.form.idcard2Image =''
              }
              if(this.urlList3.length>0){
                 this.form.contractImageList = this.urlList3;
              }else{
                this.form.contractImageList = [];
              }
              this.form.sourceType = 5
              bzApply_save(this.form).then(res=>{
                this.$message.success('添加成功！')
                this.dialogVisible = false;
                this.clearnF();
              })
              
            }
       })
    },
    /**
     * 选择用气类型
     */
    selectType(val){
      this.getOption(val)
    },
    /**
     * 类型转换
     */
    getOption(type){
        if(!this.search.type&&!type){
          selectDictListByParentCode({parentCode:'70001'}).then(res=>{
          if(res.code==200){
            var data1= res.data
             selectDictListByParentCode({parentCode:'70000'}).then(res=>{
              if(res.code==200){
                var data2 = res.data
                this.useList = data1.concat(data2)
              }
            })
          }
        })
        }else{
          if(type){
            var code = type==1?'70001':'70000';
          }else{
            var code = this.search.type==1?'70001':'70000';
          }
          selectDictListByParentCode({parentCode:code}).then(res=>{
            if(res.code==200){
              this.useList = res.data
              this.addUseList = res.data
            }
          })
        }
        
      },
    async repairBeforUploadFun1(e){
      this.repairBeforFileFormData.append('file',e.file)
     await uploadImg(this.repairBeforFileFormData).then((res)=> {
         this.urlList1.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
    async  repairBeforUploadFun2(e){
      this.repairBeforFileFormData.append('file',e.file)
    await  uploadImg(this.repairBeforFileFormData).then((res)=> {
         this.urlList2.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
    async  repairBeforUploadFun3(e){
      this.repairBeforFileFormData.append('file',e.file)
     await uploadImg(this.repairBeforFileFormData).then((res)=> {
         this.urlList3.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
      beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['jpg','jpeg','png','gif','bmp','JPG','JPEG','PBG','GIF','BMP'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
      const size = file.size / 1024 / 1024
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg1(file, fileList) {
      this.upload1Add = false;
      this.repairBeforFilefileList1 = fileList
    },
    beforHFhandleChangeImg2(file, fileList) {
      this.upload2Add = false;
      this.repairBeforFilefileList2 = fileList
    },
    beforHFhandleChangeImg3(file, fileList) {
      this.repairBeforFilefileList3 = fileList
    },
    handleRemove1(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList1,file);
    this.urlList1.splice(index,1)
    setTimeout(() => {
      this.upload1Add = true
    }, 1000)
    
    },
    handleRemove2(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList2,file);
    this.urlList2.splice(index,1)
    setTimeout(() => {
      this.upload2Add = true
    }, 1000)
     
    },
    handleRemove3(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList3,file);
    this.urlList3.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    msgLimit(file,fileList){
      this.$message.error("最多上传10张图片");
    },
    clearnF(){
      this.form={
        type:'1',
        address:'',
        contractImageList:[],
        corporation:'',
        idcard:'',
        idcard1Image:'',
        idcard2Image:'',
        name:'',
        tel:'',
        type:'',
        useDesc:''
      }
      this.urlList1 = [];
      this.urlList2 = [];
      this.urlList3 = [];
      if(this.$refs.addform){
        this.$refs['addform'].clearValidate();
      }
    },
    /**
     * 第三方弹窗
     */
    addevent(){
      this.form = {
        eventCodeName: [],
        eventAddress: "",
      };
      if(this.$refs.form){
        this.$refs['form'].clearValidate();
      }
      this.showAddModel = true
    },
    /**
     * 获取事件类型
     */
    showEventTypeList() {
      dicByType({ parentCode: "GAS_PATROL_EVENT", level: 2 }).then((res) => {
        if (res.code === 200) {
          for (let i = 0; i < res.data.length; i++) {
            let el = res.data[i];
            if (!el.children) {
              res.data.splice(i, 1);
              i = i - 1;
            }
          }
          this.eventTypeList = res.data;
        }
      });
    },
    /**
     * 添加的时候的选择类型
     */
    eventCodeSelect() {
      this.form.eventCodeId = this.form.eventCodeName[1];
    },
    /**
     * 地图地点选择
     */
    closeModel(address, lnglat) {
      if (!address && !lnglat) {
        this.showModel = false;
      } else {
        this.form.locations = lnglat;
        this.form.eventAddress = address;
        this.showModel = false;
        this.$forceUpdate();
      }
    },
    /**
     * 提交
     */
    submitAdd(form) {
      let that = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.eventCodeId) {
            if (this.form.eventAddress) {
              that.saveForm();
            } else {
              this.$message.error("请选择地址");
            }
          } else {
            this.$message.error("请选择事件类型");
          }
        }
      });
    },
    /**
     * 新增事件
     */
    saveForm() {
      saveevent(this.form).then((res) => {
        if (res.code === 200) {
          this.showAddModel = false;
          this.$message.success(res.msg);
        }
      });
    },
    /**
     * 添加事件的关闭
     */
    closeeventDialog(form) {
      this.form = {
        eventCodeName: [],
        eventAddress: "",
      };
      this.$refs[form].resetFields();
      // this.$refs.upload.clearFiles();
      this.$eventBus.$emit("coordinate");
    },
    /**
     * 编辑的情况
     */
    compile(id,first){
      // 业务工单
      if(first == 'first') {
        this.cornerMark = 'ywgd'
        YWorderDetails({id}).then(res => {
          this.paramsData = res.data
          // 特殊处理
          this.paramsData.priorLevel = String(res.data.priorLevel)
          this.paramsData.equipmentTypees = res.data.repairType
        })
      }
      // 咨询工单 
      else if(first == 'fourth') {
        this.cornerMark = 'zxgd'
        ZXorderDetails({id}).then(res => {
          this.paramsData = res.data
          // 特殊处理
          this.paramsData.priorLevel = String(res.data.priorLevel)
          this.paramsData.equipmentTypees = res.data.repairType
        })
      }
      // 投诉工单
      else if(first == 'second') {
        this.cornerMark = 'tsgd'
        TSorderDetails({id}).then(res => {
          this.paramsData = res.data
          // 特殊处理
          this.paramsData.priorLevel = String(res.data.priorLevel)
          this.paramsData.equipmentTypees = res.data.repairType
        })
      }
    },
    /**
     * 提交事件
     */
    save(){
      let that = this
      console.log(that.cornerMark,that.cornerMark == 'zxgd')
      this.$refs.search.validate((valid) => {
        console.log(valid)
        if (valid) {
          // 业务
          if(that.cornerMark == 'ywgd') {
          businessSaveOrder(that.paramsData).then(res => {
            this.$message.success(res.data)
            this.closeadd()
          })
        } else if (that.cornerMark == 'tsgd'){
          // 投诉
          TSsaveOrder(that.paramsData).then(res => {
            this.$message.success(res.data)
            this.closeadd()
          })
        }
        // 咨询
        else if (that.cornerMark == 'zxgd'){
          console.log("进来没")
          ZXsaveOrder(that.paramsData).then(res => {
            this.$message.success(res.data)
            this.closeadd()
          })
        }
        }
      })
    },
    // 更新
    updateData(){
       let that = this
      this.$refs.search.validate((valid) => {
        if (valid) {
          let treeData = this.$refs.cascader.getCheckedNodes()
          this.repairTypeNameList = []
          this.treedaras(treeData[0])
          // 业务
          if(that.cornerMark == 'ywgd') {
          YWupdateOrder(that.paramsData).then(res => {
            this.$message.success(res.data)
            this.closeadd()
          })
          } else if (that.cornerMark == 'tsgd'){
            // 投诉
            TSupdateOrder(that.paramsData).then(res => {
              this.$message.success(res.data)
              this.closeadd()
            })
          }
          // 咨询
          else if (that.cornerMark == 'zxgd'){
            ZXupdateOrder(that.paramsData).then(res => {
              this.$message.success(res.data)
              this.closeadd()
            })
          }
        }
      })
    },
    /**
     * 选择报修类型
     */
    deformation(val){
      let equipmentTypees
      equipmentTypees =  JSON.stringify(this.paramsData.equipmentTypees)
      // 没选择相同类型的
      if(this.cornerMark != val[0]){
        if(this.$refs.search) {
          this.$nextTick(() => {
            this.$refs.search.clearValidate()
          })
        }
        // 显示哪个
        this.cornerMark = val[0]
        // 选择业务的时候
        if(this.cornerMark == 'ywgd') {
          this.paramsData = {
            residentType:1,
            source:1,
            priorLevel:"1",
            contactName:'',
            contactMobile:'',
            address:''
          }
        } else if (this.cornerMark == 'tsgd'){
          // 投诉
          this.paramsData = {
            residentType:1,
            source:1,
            complainType:1,
            contactName:'',
            contactMobile:'',
            address:'',
            complainedUser:''
          }
        }
        // 咨询
        else if (this.cornerMark == 'zxgd'){
          this.paramsData = {
            residentType:1,
            source:1,
            solveStatus:1,
            contactName:'',
            contactMobile:'',
            address:''
          }
        }
      } 
      // 传值
      this.paramsData.repairType = val[val.length-1]
      this.paramsData.equipmentTypees = JSON.parse(equipmentTypees)
      let treeData = this.$refs.cascader.getCheckedNodes()
      this.repairTypeNameList = []
      this.treedaras(treeData[0])
    },
    /**
     * 组合值
     */
    treedaras(val){
      if(val.parent) {
        this.repairTypeNameList.push(val.label)
        this.treedaras(val.parent)
      } else {
        if(this.repairTypeNameList.length == 0 ){
          this.repairTypeNameList.push(val.label)
        }
        this.paramsData.repairTypeName = this.repairTypeNameList.reverse().join('>')
      }
    },
    /**
     * 巡检事件（打开巡检列表）
     */
    selectEvent() {
      this.eventListD = true;
      this.searchEvent = { current: 1, size: 100,residentId:'' };
      // this.searchEvent.type = 1
      this.getEventList(1);
    },
    /**
     * 修改用户类型的时候
     */
    updataResident(val){
        // this.paramsData.residentName  = ''
        this.paramsData.residentId = ''
        this.$set(this.paramsData,'residentName','')
        this.$forceUpdate()
    },
    // 用户重置
    resizeEvent() {
      this.searchEvent = { current: 1, size: 100};
      this.getEventList(1);
    },
    /**
     * 表格选中事件
     */
    handleSelectionChangeEvent(val) {
      if(val){
        this.searchEventZancun.residentId = val.id; 
        this.searchEventZancun.residentName = this.paramsData.residentType == 1 ? val.residentName : val.userName
        this.searchEventZancun.contactName = this.paramsData.residentType == 1 ? val.residentName : val.userName;
        this.searchEventZancun.contactMobile = this.paramsData.residentType == 1 ? val.mobile : val.userPhone;
        this.searchEventZancun.address =val.address ;
      }
    },
    /**
     * 用户人员（列表弹窗选中事件）
     */
    submitSelectEvent() {
      if(!this.searchEventZancun.residentId){
        this.$message.warning('请至少选一条数据')
        return
      }else{
        this.paramsData.residentName = this.searchEventZancun.residentName
        this.paramsData.residentId = this.searchEventZancun.residentId
        this.paramsData.contactName = this.searchEventZancun.contactName
        this.paramsData.contactMobile = this.searchEventZancun.contactMobile
        this.paramsData.address = this.searchEventZancun.address
        this.eventListD = false
      }
    },
    /**
     * 获取用户列表
     */
    getEventList(page) {
      if (page) {
        this.searchEvent.current = 1;
      }
      // 民用
      if(this.paramsData.residentType == 1) {
        userPageList(this.searchEvent).then(res => {
          this.tableDataEvents = res.data.records
          this.totalEvents = res.data.total
        })
      } else {
        // 非民
        unUserPageList(this.searchEvent).then(res => {
          this.tableDataEvents = res.data.records
          this.totalEvents = res.data.total
        })
      }
    },
    handleCurrentChangeEvent(val) {
      this.searchEvent.current = val;
      this.getEventList();
    },
    handleSizeChangeEvent(val) {
      this.searchEvent.size = val;
      this.getEventList(1);
    },
    /**
     * 报修类型字典
     */
    Getdicpage(first){
      let that = this
      dicpage({platform:6}).then(res => {
        if(res.code == 200) {
          res.data.forEach(list => {
            if(list.code == 'bxlx'){
              that.selectDownArray = list.children
              // 业务工单
              if(first == 'first') {
                list.children.forEach(el => {
                  if(el.code == "ywgd") {
                    that.selectDownArray = [el]
                  }
                })
              }
              // 咨询工单 
              else if(first == 'fourth') {
                list.children.forEach(el => {
                  if(el.code == "zxgd") {
                    that.selectDownArray = [el]
                  }
                })
              }
              // 投诉工单
              else if(first == 'second') {
                list.children.forEach(el => {
                  if(el.code == "tsgd") {
                    that.selectDownArray = [el]
                  }
                })
              }
              this.childrenData(that.selectDownArray)
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    childrenData(val) {
      val.forEach(el => {
        if(el.children && el.children.length>0){
          this.childrenData(el.children)
        } else if(el.value == 1) {
          el.disabled=true
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>

.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh - 155px);
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  position: relative;
  // margin-bottom: 0;
  height: 100%;
  background-color: #ffffff;
}
.backbtn {
  margin: 30px 0px 40px 160px;
  width: 82px;
  height: 36px;
}
.searchForm{
  /deep/.el-form-item__error{
    top: 81%;
  }
  /deep/.events .el-form-item__error{
    top: 100%;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
}
::v-deep .el-upload--picture-card{height:125px;width:190px;line-height:125px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{height:125px;width:190px;line-height:125px}
::v-deep .el-upload--picture-card {border: none;}
.uploadImg1{height:125px;width:190px}
.uploadImg2{height:125px;width:190px;margin-left: 200px;}
</style>
